import Signature, { SignatureRef } from "@uiw/react-signature"
import { useEffect, useRef } from "react";
import styles from "./CustomSignature.module.css";
import CustomButton from "../Button/Button";


const CustomSignature = ({ isFirma, changesetIsFirma }: any) => {
   const signatureRef = useRef<SignatureRef>(null);

   const handleClear = () => {
      signatureRef.current?.clear();
   };
   const options = {
      size: 4.1,
      smoothing: 0.46,
      thinning: 0.73,
      streamline: 0.5,
      easing: (t: any) => t,
      simulatePressure: true,
      last: true,
      start: {
         cap: true,
         taper: 0,
         easing: (t: any) => t,
      },
      end: {
         cap: true,
         taper: 0,
         easing: (t: any) => t,
      },
   }
   const handleSave = async () => {
      const svgElement = signatureRef.current?.svg;
   
      // Verificar si la firma está vacía (el contenido SVG es nulo o vacío)
      if (!svgElement || svgElement.innerHTML.trim() === "") {
         alert("Por favor, ingrese una firma.");
         return;
      }
   
      const svgData = new XMLSerializer().serializeToString(svgElement);
   
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
   
      const svgBlob = new Blob([svgData], { type: "image/svg+xml;charset=utf-8" });
      const url = URL.createObjectURL(svgBlob);
      const img = new Image();
      img.onload = () => {
         canvas.width = img.width;
         canvas.height = img.height;
         ctx?.drawImage(img, 0, 0);
   
         const pngData = canvas.toDataURL("image/png");
         changesetIsFirma(pngData, true);
   
         URL.revokeObjectURL(url);
      };
      img.src = url;
   };
   return (
      <>
         <div className={styles.Container__SignatureContainer}>
            <Signature
               ref={signatureRef}
               className={styles.Signature}
               width="450"
               height="230"
               options={options}
            />
         </div>

         <br />
         {
            isFirma ? (
               <div className={styles.Container__Btns}>
                  <CustomButton
                     onClick={handleClear} label="Limpiar" />
                  <CustomButton onClick={handleSave} label="Usar firma" />
               </div>
            ) : ''
         }


      </>
   );
}

export default CustomSignature