import React, { useState } from 'react';
import { PaginationProps, Table, TableProps } from 'antd';

interface CustomTableProps<T> extends TableProps<T> {
  dataSource?: T[];
  paginationConfig?: PaginationProps;
  onSelectionChange?: (selectedRowKeys: React.Key[], selectedRows: T[]) => void;
}

const CustomSelectableTable = <T extends object>({
  dataSource,
  columns,
  onSelectionChange,
  rowKey = 'key', // Default key field
  ...rest
}: CustomTableProps<T>) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const handleSelectChange = (
    selectedKeys: React.Key[],
    selectedRows: T[]
  ) => {
    setSelectedRowKeys(selectedKeys);
    if (onSelectionChange) {
      onSelectionChange(selectedKeys, selectedRows);
    }
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: handleSelectChange,
  };

  return (
    <Table<T>
      columns={columns}
      dataSource={dataSource}
      rowKey={rowKey}
      rowSelection={rowSelection}
      className="selectable-table-container"
      size="middle"
      bordered={true}
      {...rest}
    />
  );
};

export default CustomSelectableTable;
