import React, { Fragment } from 'react';
import EntryRequestsView from '../Views/EntryRequestsView';
import {
  useFetchEntryReq,
  useFetchEntryReqById,
} from '../../../../hooks/entryRequests';
import EntryRequestCreateContainer from './EntryRequestCreateContainer';
import EntryRequestDetailContainer from './EntryRequestDetailContainer';
import { ModalType, useFormikEntryFilters } from '../../../../utils';
import { EntryFilter } from '../../types';
import { useEntryStates, useSupervisors } from '../../../../share';

const EntryRequestsContainer: React.FC = () => {
  const [filter, setFilter] = React.useState<EntryFilter>();
  const { data: supervisors } = useSupervisors();
  const [modalState, setModalState] = React.useState<{
    type: ModalType;
    entryId?: string;
  }>({ type: null, entryId: undefined });

  const {
    data: entries,
    isPending: loadList,
    refetch,
  } = useFetchEntryReq(filter);

  const { data: entry, isPending: loadById } = useFetchEntryReqById(
    modalState.entryId
  );
  const { data: entryStates } = useEntryStates();
  const handleModalToggle = (type: ModalType, id?: string) => {
    setModalState((prev) => ({
      type: prev.type === type ? null : type,
      entryId: id,
    }));
  };

  const handlePagination = (page: number) => {
    setFilter({
      page: page.toString(),
    });
  };

  const handleFilter = async (values: Partial<EntryFilter>) => {
    setFilter({
      ...values,
      created_at: values.created_at
        ? new Date(values.created_at).toISOString().split('T')[0]
        : '',
    });
    refetch();
  };

  const formik = useFormikEntryFilters({ onSubmit: handleFilter });

  return (
    <Fragment>
      <EntryRequestCreateContainer
        isOpen={modalState.type === 'create'}
        OnClose={() => handleModalToggle('create')}
      />
      <EntryRequestDetailContainer
        isOpen={modalState.type === 'detail'}
        OnClose={() => handleModalToggle('detail')}
        data={entry as any}
        isLoading={loadById}
      />

      <EntryRequestsView
        isLoading={loadList}
        data={entries}
        states={entryStates}
        supervisors={supervisors}
        formik={formik}
        onViewCreate={() => handleModalToggle('create')}
        onViewDetail={(id) => handleModalToggle('detail', id)}
        onPagination={handlePagination}
        onResetFilters={() => {
          handleFilter({});
          formik.resetForm();
        }}
      />
    </Fragment>
  );
};

export default EntryRequestsContainer;
