import React, { useEffect, useState } from 'react';
import styles from "./ModalSaveChanges.module.css";

import {
  Modal as AntModal,
  ModalProps as AntModalProps,
  Button,
  Row,
  Col,
} from 'antd';
import CustomLabel from '../Label/CustomLabel';
import CustomButton from '../Button/Button';
import CustomText from '../CustomText/CustomText';
import CustomSignature from '../CustomSignature/CustomSignature';

interface ModalProps extends AntModalProps {
  title: string;
  footerLeft?: React.ReactNode;
  onSave?: (data: any) => void;
  onClose: () => void;
  labelSubmit?: string;
  isLoading?: boolean;
  isLoadingSave?: boolean;
  handledDataFirma?: (data: string) => void
}


const ModalSaveChanges: React.FC<ModalProps> = ({
  handledDataFirma,
  title,
  children,
  footerLeft,
  onClose,
  onSave,
  labelSubmit,
  isLoading = false,
  isLoadingSave = false,
  ...props
}) => {

  const [isShowFirma, setIsShowFirma] = useState(false)
  const [isFirma, setIsFirma] = useState(false)



  const changesetIsFirma = (data: any, opc: boolean) => {
    if (handledDataFirma) { 
      handledDataFirma(data); 
    } else {
      console.warn("handledDataFirma no está definido");
    }
    setIsFirma(true)
    setIsShowFirma(false)
    console.log("Firma capturada:", data);

  }
  const handleEventCheck = (opc: boolean) => {
    setIsShowFirma(opc)
    setIsFirma(false)


  }
  return (
    <AntModal
      className={styles.Modal}
      loading={isLoading}
      {...props}
      closeIcon={
        <span className="custom-close-icon" onClick={onClose}>
          x
        </span>
      }
      footer=""
    >
      <div className={styles.Container}>
        <img src='/ModalValidator.svg' className={styles.Container__Img} />
        <CustomLabel align="center" text={title} />
        <CustomText customClass={styles.Containe__CustomText} text="¿Está seguro de crear esta solicitud de Concepto técnico?" />
        <CustomText customClass={styles.Containe__CustomText} text="Una vez creado no podrá realizar cambios." />
        <div className={styles.Container__Checkbox}>
          <input type='checkbox' id='firma' onChange={() => handleEventCheck(!isShowFirma)} checked={isShowFirma} />
          <label className={styles.Container__Label} htmlFor='firma'><CustomText text={isFirma ? "Cambiar firmar " : "Firmar solicitud"} /></label>
        </div>
        {
          isShowFirma ? (
            <div className={styles.Container__Firma}>
              <CustomSignature changesetIsFirma={changesetIsFirma} isFirma={isShowFirma} />
            </div>
          ) : ''
        }
        {
          isFirma ? (
            <div className={styles.Container__HayFirma}>
              <img src='/FirmaAgregada.svg' /> <CustomText text='Firma agregada' />
            </div>
          ) : ''
        }

        {
          !isShowFirma ? (
            <div className={styles.Container__Btns}>
              <CustomButton
                buttonType={'secondary'}
                label={'No'}
                onClick={onClose}
                size="middle"
              />
              <CustomButton
                label={'Si'}
                loading={isLoadingSave}
                onClick={onSave}
                size="middle"
              />
            </div>
          ) : ''
        }

      </div>
    </AntModal >
  )
}

export default ModalSaveChanges