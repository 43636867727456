import React from 'react';
import { DatePicker, Form } from 'antd';

interface FormDatePickerProps {
  label: string;
  name: string;
  placeholder?: string;
  value: any;
  error?: string | undefined;
  touched?: boolean | undefined;
  onChange: (value: any) => void;
  onBlur: () => void;
  isRange?: boolean;
  showTime?: boolean;
}

const FormDatePicker: React.FC<FormDatePickerProps> = ({
  label,
  name,
  placeholder,
  value,
  error,
  touched,
  onChange,
  onBlur,
  isRange = false,
  showTime = false,
}) => {
  const DatePickerComponent = isRange ? DatePicker.RangePicker : DatePicker;

  return (
    <Form.Item
      label={<span className="custom-label">{label}</span>}
      validateStatus={touched && error ? 'error' : undefined}
      help={touched && error}
      labelCol={{ span: 24 }}
    >
      <DatePickerComponent
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        format={showTime ? 'YYYY/MM/DD HH:mm:ss' : 'YYYY/MM/DD'}
        showTime={showTime}
        size="middle"
        style={{ width: '100%' }}
        placeholder={placeholder ? placeholder as any: 'Seleccione'}
      />
    </Form.Item>
  );
};

export default FormDatePicker;
