import React, { useState } from 'react';
import { Layout, Menu, Breadcrumb, Button, Drawer } from 'antd';
import { Outlet, Link, useLocation } from 'react-router-dom';
import {
  MenuOutlined,
  HomeOutlined,
  FileTextOutlined,
  AppstoreAddOutlined,
  ShoppingCartOutlined,
  DollarCircleOutlined,
  DownOutlined,
} from '@ant-design/icons';
import '../styles/MainLayout.css';
import logo from '../assets/logos/logo-fiscalia.jpg';
import { RoundFiscalia, RoundLogout } from '../assets/svgs';
import { useLogout } from '../hooks';
import { useAtom } from 'jotai';
import { authAtom } from '../state';
const { Header, Content } = Layout;

const MainLayout: React.FC = () => {
  const location = useLocation();
  const [authData] = useAtom(authAtom);
  const { mutateAsync } = useLogout();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const menuMainItems = [
    {
      key: '/one',
      label: <Link to="#">Sistema de Gestión de Recusos</Link>,
    },

    {
      key: '/two',
      label: <Link to="#">Mi inventario en linea</Link>,
    },
  ];

  const menuItems = [
    {
      key: '/',
      label: (
        <span>
          <HomeOutlined style={{ marginRight: 8 }} />
          <Link to="/">Inicio</Link>
        </span>
      ),
    },
    {
      key: '/myrequests',
      label: (
        <span>
          <FileTextOutlined style={{ marginRight: 8 }} />
          <Link to="/myrequests">Mis solicitudes</Link>
        </span>
      ),
    },
    {
      key: '/entryrequests',
      label: (
        <span>
          <AppstoreAddOutlined style={{ marginRight: 8 }} />
          <Link to="/entryrequests">Solicitudes de Entrada</Link>
        </span>
      ),
    },
    {
      key: '/goodsrequests',
      label: (
        <span>
          <ShoppingCartOutlined style={{ marginRight: 8 }} />
          <Link to="/goodsrequests">Solicitud de bienes</Link>
        </span>
      ),
    },
    {
      key: '/refunds',
      label: (
        <span>
          <DollarCircleOutlined style={{ marginRight: 8 }} />
          <Link to="/refunds">Solicitud de reintegros</Link>
        </span>
      ),
    },
    {
      key: '/transfers',
      label: (
        <span>
          <DollarCircleOutlined style={{ marginRight: 8 }} />
          <Link to="/transfers">Traslados funcionarios</Link>
        </span>
      ),
    },
  ];

  const toggleDrawer = () => setIsDrawerOpen(!isDrawerOpen);

  return (
    <Layout className="layout-container">
      {/* HEADER */}
      <Header className="header-container header-main-container">
        <div className="header-left">
          <img
            src={logo}
            alt="Fiscalía"
            className="header-logo"
            width={150}
            height={50}
          />
        </div>
        <div className="header-center">
          <Menu
            theme="dark"
            mode="horizontal"
            selectedKeys={[location.pathname]}
            items={menuMainItems}
            className="menu-desktop"
          />
        </div>
        <div className="header-right">
          <div className="user-info">
            <h2 className="user-name">
              {authData?.user.name} {authData?.user.last_name}
            </h2>
            <h5 className="user-name">{authData?.role}</h5>
          </div>
          <div className="notification-container">
            <RoundFiscalia
              width={45}
              height={45}
              onClick={() => mutateAsync()}
              cursor={'pointer'}
            />
            <span className="notification-dot"></span>
          </div>
          <DownOutlined
            style={{
              color: 'white',
              fontSize: '18px',
            }}
          />
          <RoundLogout
            width={35}
            height={35}
            onClick={() => {
              mutateAsync();
            }}
            cursor={'pointer'}
          />
        </div>
      </Header>

      <Header className="header-container header-submain-container">
        <div className="header-center">
          <Menu
            mode="horizontal"
            selectedKeys={[location.pathname]}
            items={menuItems}
            className="menu-desktop"
          />
          <Button
            type="text"
            icon={<MenuOutlined />}
            className="menu-hamburger"
            onClick={toggleDrawer}
          />
          <Drawer
            title="Menú"
            placement="right"
            onClose={toggleDrawer}
            open={isDrawerOpen}
          >
            <Menu
              mode="vertical"
              selectedKeys={[location.pathname]}
              items={menuItems}
            />
          </Drawer>
        </div>
      </Header>

      {/* BREADCRUMB */}
      <Content className="content-container">
        <Breadcrumb separator=">" className="breadcrumb-container">
          <Breadcrumb.Item>
            <Link to="/">Inicio</Link>
          </Breadcrumb.Item>
          {location.pathname !== '/' && (
            <Breadcrumb.Item>
              {menuItems.find((item) => item.key === location.pathname)
                ?.label || 'Sección'}
            </Breadcrumb.Item>
          )}
        </Breadcrumb>

        <div className="outlet-container">
          <Outlet />
        </div>
      </Content>

      {/* FOOTER */}
      {/* <Footer className="footer-container">©2025</Footer> */}
    </Layout>
  );
};

export default MainLayout;
