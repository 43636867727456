import React, { useEffect, useState } from 'react';
import EntryRequestCreateView from '../Views/EntryRequestCreateView';
import { CustomModal } from '../../../../components';
import {
  useCreateEntryReq,
  useModalSeccional,
} from '../../../../hooks/entryRequests';
import { UploadedFile, useEntryRequestFormik } from '../../../../utils';
import { EntryRequest } from '../../types';
import { useAtom } from 'jotai';
import { authAtom } from '../../../../state';

import {
  useDeliveryOptions,
  useDependecies,
  useEntryStates,
  useEntyTypes,
  useGoodStates,
  useItemTypes,
  useSedes,
  useSupervisors,
} from '../../../../share';
import { ModalSeccional } from '../components';
import ModalSaveChanges from '../../../../components/ModalSaveChanges/ModalSaveChanges';

interface EntryRequestsCreateProps {
  isOpen: boolean;
  OnClose: () => void;
}

const EntryRequestCreateContainer: React.FC<EntryRequestsCreateProps> = ({
  isOpen,
  OnClose,
}) => {
  const [isOpenSaveChanges, setiIsOpenSaveChanges] = useState(false)
  const [files, setFiles] = React.useState<UploadedFile[]>();
  const [authData] = useAtom(authAtom);
  const [dataFirma, setDataFirma] = useState("");

  const { mutateAsync, isPending: loadCreate } = useCreateEntryReq();
  const { data: entyTypes } = useEntyTypes();
  const { data: itemTypes } = useItemTypes();
  const { data: entryStates } = useEntryStates();
  const { data: goodStates } = useGoodStates();
  const { data: entryDevelivery } = useDeliveryOptions();
  const { data: sedes } = useSedes();
  const { data: supervisors } = useSupervisors();
  const { data: dependencies } = useDependecies();

  const handledDataFirma = (data: string) => {
    setDataFirma(data)
  }
  const onSave = async (values: EntryRequest) => {
    console.log('dataFirma', dataFirma)
    const delivey_section = items.map((item) => ({
      section_id: item.seccional,
      invoice_number: item.factura,
      quantity: Number(item.cantidad),
    }));

    try {
      await mutateAsync({
        ...values,
        delivey_section,
        requester_id: authData?.user.id.toString(),
        invoice_date: new Date(values.invoice_date).toISOString().split('T')[0],
        documents: files,
        signature: dataFirma
      });

    } catch (error) {
      console.error('Error al guardar:', error);
    }
  };


  const confirmSaveChanges = async () => {
    try {
      await onSave(formik.values); // Llama a onSave con los valores actuales

      onReset() // Cierra el modal después de guardar
    } catch (error) {
      console.error("Error al guardar:", error);
    }
  };
  const validateAndOpenModal = async () => {
    const errors = await formik.validateForm(); // Ejecuta la validación manualmente
    formik.setTouched(
      Object.keys(formik.initialValues).reduce(
        (acc, key) => ({ ...acc, [key]: true }),
        {}
      )
    );
    if (Object.keys(errors).length === 0) {
      setiIsOpenSaveChanges(true);
    } else {
      console.log("Errores en el formulario:", errors);
    }
  };
  const formik = useEntryRequestFormik({
    onSubmit: async () => {
      // Este onSubmit queda vacío porque la validación ya se maneja antes de abrir el modal
    },
  });

  const closeModalSaveChanges = () => {
    setiIsOpenSaveChanges(false);
  }

  const handleSubmit = () => {
    formik.handleSubmit();
  };

  const onReset = () => {
    closeModalSaveChanges();
    setTimeout(() => {
      formik.resetForm();
      OnClose();
      setFiles([]);
    }, 1000)

  };
  //Seccional
  const {
    data: items,
    isOpen: isOpenSeccional,
    formik: formikSeccional,
    currentPage,
    totalCantidad,
    onReset: onResetSeccional,
    handlePagination: onPagination,
    handleDelete,
    setIsOpen,
  } = useModalSeccional(formik.values.deliveries || 10);



  return (
    <CustomModal
      title="Creación de solicitud de Entrada"
      labelSubmit="Crear Solicitud"
      open={isOpen}
      onCancel={() => {
        onReset();
      }}
      width={800}
      isLoadingSave={loadCreate}
      onSave={validateAndOpenModal}
      onClose={() => {
        onReset();
      }}
    >
      <EntryRequestCreateView
        entyTypes={entyTypes}
        itemTypes={itemTypes}
        entryDevelivery={entryDevelivery}
        entryStates={entryStates}
        goodStates={goodStates}
        authData={authData}
        sedes={sedes}
        supervisors={supervisors}
        dependencies={dependencies}
        formik={formik}
        onSave={handleSubmit}
        onCancel={onReset}
        onFiles={setFiles}
        setIsOpen={setIsOpen}
        files={files}
      />
      <ModalSeccional
        items={items}
        currentPage={currentPage}
        sedes={sedes?.length ? sedes : []}
        isOpen={isOpenSeccional}
        totalCantidad={totalCantidad}
        formik={formikSeccional}
        onReset={onResetSeccional}
        onPagination={onPagination}
        handleDelete={handleDelete}
        setIsOpen={setIsOpen}
      />
      <ModalSaveChanges
        title="Guardar Cambios"
        open={isOpenSaveChanges}
        width={900}
        isLoadingSave={false}
        onClose={() => {
          closeModalSaveChanges();
        }}
        labelSubmit="Aceptar"
        onSave={() => confirmSaveChanges()}
        handledDataFirma={handledDataFirma} />
    </CustomModal>
  );
};

export default EntryRequestCreateContainer;
