import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ENTRY_TYPE, EntryRequest } from '../../../modules/EntryRequests/types';
import { DateTime } from 'luxon';
import dayjs, { Dayjs } from 'dayjs';
import moment from 'moment';

// Esquema de validación
export const entryRequestValidationSchema = Yup.object({
  entry_type: Yup.string().required('Requerido') as any,
  item_type: Yup.string().required('Requerido'),
  branch_id: Yup.string().required('Requerido'),
  deliveries: Yup.number()
    .required('Requerido')
    .min(1, 'Debe haber al menos una entrega')
    .max(100, 'Debe haber maximo 100 entregas'),
  section_id: Yup.string().required('Requerido'),
  dependency_id: Yup.string().required('Requerido'),
  supervisor: Yup.string().required('Requerido'),
  invoice_number: Yup.string().max(100, 'Máximo 100 caracteres').required('Requerido'),
  other_deliver: Yup.string().required('Requerido'),
  invoice_date: Yup.date().nullable().required('Requerido'),
  initial_date: Yup.date().nullable().required('Requerido'),
  initial_dateDisable: Yup.string().required('Requerido'),
  observations: Yup.string().max(500, 'Máximo 500 caracteres'),
  number_decomiso: Yup.string().max(32, 'Máximo 32 caracteres').required('Requerido'),
  number_decomisoProceso: Yup.string().max(32, 'Máximo 32 caracteres').required('Requerido'),
  judgment_number_sentencia: Yup.number().max(32, 'Máximo 32 caracteres').required('Requerido'),
  numberInvoices: Yup.number().max(10, 'Máximo 10 caracteres').required('Requerido'),

  
  //Orden de compra
  purchase_order: Yup.string().when('entry_type', {
    is: ENTRY_TYPE.ORDEN_COMPRA,
    then: (schema) => schema.required('Requerido'),
    otherwise: (schema) => schema.nullable(),
  }),
  invoice_value: Yup.number()
    .min(0, 'El valor debe ser positivo')
    .when('entry_type', {
      is: ENTRY_TYPE.ORDEN_COMPRA,
      then: (schema) => schema.required('Requerido'),
      otherwise: (schema) => schema.nullable(),
    }),
  // Contrato
  contract_number: Yup.string().when('entry_type', {
    is: ENTRY_TYPE.CONTRATO,
    then: (schema) => schema.required('Requerido'),
    otherwise: (schema) => schema.nullable(),
  }),
  contract_start_date: Yup.date()
    .nullable()
    .when('entry_type', {
      is: ENTRY_TYPE.CONTRATO,
      then: (schema) => schema.required('Requerido'),
      otherwise: (schema) => schema.nullable(),
    }),
  contract_end_date: Yup.date()
    .nullable()
    .when('entry_type', {
      is: ENTRY_TYPE.CONTRATO,
      then: (schema) => schema.required('Requerido'),
      otherwise: (schema) => schema.nullable(),
    }),

  // Sentencia Judicial
  judgment_court_name: Yup.string().when('entry_type', {
    is: ENTRY_TYPE.SENTENCIA_JUDICIAL,
    then: (schema) => schema.required('Requerido'),
    otherwise: (schema) => schema.nullable(),
  }),
  judgment_process_number: Yup.string().when('entry_type', {
    is: ENTRY_TYPE.SENTENCIA_JUDICIAL,
    then: (schema) => schema.required('Requerido'),
    otherwise: (schema) => schema.nullable(),
  }),

  // Contacto
  contact_nit: Yup.string().when('entry_type', {
    is: ENTRY_TYPE.CONTRATO,
    then: (schema) => schema.required('Requerido'),
    otherwise: (schema) => schema.nullable(),
  }),
  contact_name: Yup.string().when('entry_type', {
    is: ENTRY_TYPE.CONTRATO,
    then: (schema) => schema.required('Requerido'),
    otherwise: (schema) => schema.nullable(),
  }),
  contact_person: Yup.string().when('entry_type', {
    is: ENTRY_TYPE.CONTRATO,
    then: (schema) => schema.required('Requerido'),
    otherwise: (schema) => schema.nullable(),
  }),
  contact_phone: Yup.string().when('entry_type', {
    is: ENTRY_TYPE.CONTRATO,
    then: (schema) => schema.required('Requerido'),
    otherwise: (schema) => schema.nullable(),
  }),

  // Donación
  donation_document: Yup.string().when('entry_type', {
    is: ENTRY_TYPE.DONACION,
    then: (schema) => schema.required('Requerido'),
    otherwise: (schema) => schema.nullable(),
  }),

  // COMODATO
  number_comodato: Yup.string().when('entry_type', {
    is: ENTRY_TYPE.COMODATO,
    then: (schema) =>
      schema
        .required('Requerido')
        .matches(/^[a-zA-Z0-9]+$/, 'Solo se permiten caracteres alfanuméricos')
        .max(10, 'Máximo 32 caracteres'),
    otherwise: (schema) => schema.nullable(),
  }),

  start_date_comodato: Yup.date()
    .nullable()
    .when('entry_type', {
      is: ENTRY_TYPE.COMODATO,
      then: (schema) => schema.required('Requerido'),
      otherwise: (schema) => schema.nullable(),
    }),

  end_date_comodato: Yup.date()
    .nullable()
    .when('entry_type', {
      is: ENTRY_TYPE.COMODATO,
      then: (schema) => schema.required('Requerido'),
      otherwise: (schema) => schema.nullable(),
    }),
  
});


// Hook customizado
interface UseEntryRequestFormikProps {
  onSubmit: (values: EntryRequest) => void;
}

const useEntryRequestFormik = ({ onSubmit, }: UseEntryRequestFormikProps) => {
  return useFormik<EntryRequest>({
    initialValues: {
      entry_type: '',
      item_type: '',
      branch_id: '',
      deliveries: 0,
      section_id: '',
      dependency_id: '',
      supervisor: '',
      other_deliver: '',
      invoice_number: '',
      invoice_date: '',
      initial_date: '',
      initial_dateDisable: '',
      number_decomiso: '',
      number_decomisoProceso: '',
      judgment_number_sentencia: 0,
      numberInvoices: 1,
      //Orden de compra
      invoice_value: '',
      purchase_order: '',
      observations: '',

      //CONTRATO
      contract_number: '',
      contract_start_date: '',
      contract_end_date: '',

      //SENTENCIA JUDICIAL
      judgment_court_name: '',
      judgment_process_number: '',

      /*   //INFORMACION DE CONTACTO
        contact_name: '',
        contact_phone: '',
        contact_nit: '',
        contact_person: '', */

      //DONACION
      donation_document: '',

      //COMODATO
      number_comodato: '',
      start_date_comodato: '',
      end_date_comodato: '',
    },
    validationSchema: entryRequestValidationSchema,
    onSubmit,
  });
};

export default useEntryRequestFormik;
