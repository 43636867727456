import React, { useEffect } from 'react';
import { Form, Select } from 'antd';
import { ShareResponse } from '../../share';

const { Option } = Select;
interface ThirdParty {
  id: number;
  name: string;
}
interface FormSelectSearchProps {
  label: string;
  name: string;
  placeholder?: string;
  value: string | undefined;
  options?: ThirdParty[] | null;
  error?: string | undefined;
  touched?: boolean | undefined;
  onChange: (value: string) => void;
  onBlur: () => void;
  showSearch?: boolean;
}

const FormSelectSearch: React.FC<FormSelectSearchProps> = ({
  label,
  name,
  placeholder = 'Seleccione...',
  value,
  options = [],
  error,
  touched,
  onChange,
  onBlur,
  showSearch = false,
}) => {
  const safeOptions = Array.isArray(options) ? options : [];

  return (
    <Form.Item
      label={<span className="custom-label">{label}</span>}
      validateStatus={touched && error ? 'error' : undefined}
      help={touched && error}
      labelCol={{ span: 24 }}
      className="custom-form-item"
    >
      <Select
        title={name}
        placeholder={placeholder}
        value={value || undefined}
        onChange={(value) => {
          onChange(value); // Llama al handler de Formik con el nuevo valor
        }}
        onBlur={() => {
          onBlur(); // Llama al handler de Formik para marcar el campo como visitado
        }}
        size="middle"
        showSearch={true}
        optionFilterProp="children"
        filterOption={(input, option) => {
          const label =
            typeof option?.children === 'string'
              ? option.children
              : String(option?.children);
          return label.toLowerCase().includes(input.toLowerCase());
        }}
      >
        <Option value="" disabled>
          {placeholder}
        </Option>
        {safeOptions.map((option) => (
          <Option key={option.id} value={option.id}>
            {option.name}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default FormSelectSearch;
