import React, { Fragment } from 'react';
import { Button, Col, Divider, Row, Typography } from 'antd';
import { UploadedFile } from '../../../../utils';
import { AuthResponse } from '../../../Auth/types';
import { ShareResponse } from '../../../../share';
import { CustomButton, CustomSelectableTable, CustomTable, CustomTabs, FormInput, FormSelect, UploadWithTable } from '../../../../components';
import { ColumnsType } from 'antd/es/table';
import { AssetsResponse } from '../../../../share/types/asset-response';
import { FileTextOutlined, PlusOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { LocationResponse } from '../../../../share/types/location-response';
import { DateTime } from 'luxon';

const { Title } = Typography;

interface RefundCreateViewProps {
  formik: any;
  entyTypes?: ShareResponse[];
  itemTypes?: ShareResponse[];
  entryStates?: ShareResponse[];
  goodStates?: ShareResponse[];
  entryDevelivery?: ShareResponse[];
  sedes?: ShareResponse[];
  dependencies?: ShareResponse[];
  supervisors?: ShareResponse[];
  onSave: () => void;
  onCancel: () => void;
  authData?: AuthResponse | null;
  onFiles: (files: UploadedFile[]) => void;
  files?: UploadedFile[];
  assets?: AssetsResponse[];
  location?: LocationResponse[];
  onAdd?: (asset: AssetsResponse[]) => void;
  onDeleteAsset: (asset: number) => void;
}

const RefundCreateView: React.FC<RefundCreateViewProps> = ({
  formik,
  goodStates,
  dependencies,
  sedes,
  supervisors,
  authData,
  files,
  onFiles,
  assets,
  location,
  onAdd,
  onDeleteAsset,
}) => {

  const handleSelectionChange = (selectedRowKeys: React.Key[], selectedRows: AssetsResponse[]) => {
    onAdd?.(selectedRows);
  };

  const columns: ColumnsType<AssetsResponse> = [
    {
      title: 'Item',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Activo',
      dataIndex: 'item',
      key: 'item',
      render: (item) => item?.name,
    },
    {
      title: 'Codigo placa',
      dataIndex: 'plate_code',
      key: 'plate_code',
    },
    {
      title: 'Tipo clase',
      dataIndex: 'asset_class',
      key: 'asset_class',
      render: (asset_class) => asset_class?.name,
    },
    {
      title: 'Estado por elemento',
      dataIndex: 'status',
      key: 'status',
      render: (status) => status?.status,
    }
    // {
    //   title: 'Acciones',
    //   key: 'actions',
    //   render: (_, record, index) => (
    //     <Button danger onClick={() => {}}>
    //       Eliminar
    //     </Button>
    //   ),
    // },
  ];

  return (
    <Fragment>
      <Row gutter={[16, 16]} style={{ marginBottom: '20px' }}>
        <Col xs={24} sm={12} md={8} lg={6}>
          <FormInput
            label="Solicitante"
            name="requester_id"
            placeholder="Solicitante"
            value={`${authData?.user.name} ${authData?.user.last_name}`}
            disabled
            error={formik.errors.requester_id}
            touched={formik.touched.requester_id}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Col>
        <Col xs={24} sm={12} md={8} lg={6}>
          <FormInput
            label="Fecha"
            name="initial_date"
            placeholder="Solicitante"
            value={DateTime.now().toFormat('yyyy-MM-dd')}
            disabled
            error={formik.errors.initial_date}
            touched={formik.touched.initial_date}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Col>
        <Col xs={24} sm={12} md={8} lg={6}>
          <FormSelect
            label="Dependencia"
            name="dependency_id"
            placeholder="Seleccione"
            value={formik.values.dependency_id}
            options={dependencies}
            error={formik.errors.dependency_id}
            touched={formik.touched.dependency_id}
            onChange={(value) => formik.setFieldValue('dependency_id', value)}
            onBlur={formik.handleBlur}
          />
        </Col>
        <Col xs={24} sm={12} md={8} lg={6}>
          <FormSelect
            label="Ubicación"
            name="location_id"
            placeholder="Seleccione"
            value={formik.values.sedes}
            options={location?.map((x) => ({
              name: x.name,
              id: x.id.toString(),
            }))}
            error={formik.errors.location_id}
            touched={formik.touched.location_id}
            onChange={(value) => formik.setFieldValue('location_id', value)}
            onBlur={formik.handleBlur}
          />
        </Col>
        <Col xs={24} sm={12} md={8} lg={6}>
          <FormSelect
            label="Nuevo reponsable"
            name="new_responsible_id"
            placeholder="Seleccione"
            value={formik.values.new_responsible_id}
            options={supervisors}
            error={formik.errors.new_responsible_id}
            touched={formik.touched.new_responsible_id}
            onChange={(value) => formik.setFieldValue('new_responsible_id', value)}
            onBlur={formik.handleBlur}
          />
        </Col>
        <Col xs={24} sm={12} md={8} lg={6} className='input-disable'>
          <FormSelect
            label="Estado solicitud"
            name="status_id"
            placeholder="Seleccione"
            value={formik.values.status_id}
            options={goodStates}
            error={formik.errors.status_id}
            touched={formik.touched.status_id}
            onChange={(value) => formik.setFieldValue('status_id', value)}
            onBlur={formik.handleBlur}
          />
        </Col>
        <Col xs={24} sm={12} md={8} lg={6}>
          <FormSelect
            label="Nueva dependencia"
            name="new_dependency_id"
            placeholder="Seleccione"
            value={formik.values.new_dependency_id}
            options={dependencies}
            error={formik.errors.new_dependency_id}
            touched={formik.touched.new_dependency_id}
            onChange={(value) => formik.setFieldValue('new_dependency_id', value)}
            onBlur={formik.handleBlur}
          />
        </Col>
      </Row>
      <Divider />
      <CustomTabs
        defaultActiveKey="1"
        items={[
          {
            key: '1',
            label: (
              <Button type="link" icon={<UnorderedListOutlined />}>
                Items
              </Button>
            ),
            children: (
              <Fragment>
                <Row gutter={[16, 16]} justify="end">
                  <Col xs={24} sm={24} md={24} lg={24}>
                    <CustomSelectableTable<AssetsResponse>
                      dataSource={assets}
                      columns={columns}
                      rowKey="id"
                      onSelectionChange={handleSelectionChange}
                    />
                  </Col>
                </Row>
              </Fragment>
            )
          },
          {
            key: '2',
            label: (
              <Button type="link" icon={<FileTextOutlined />}>
                Documentos
              </Button>
            ),
            children: (
              <>
                <Title level={4} style={{ textAlign: 'center', marginBottom: '20px' }}>
                  Documentos cargados
                </Title>
                <Row>
                  <Col xs={24}>
                    <UploadWithTable onFilesChange={onFiles} files={files} />
                  </Col>
                </Row>
              </>
            ),
          }
        ]}
      />
    </Fragment>
  );
};

export default RefundCreateView;
