import React, { Fragment } from 'react';
import { Tabs, Row, Col, Table, Button, Divider } from 'antd';
import {
  FileTextOutlined,
  FileSearchOutlined,
  HistoryOutlined,
  DownloadOutlined,
  FileProtectOutlined,
} from '@ant-design/icons';
import { GoodsRequest } from '../../types/goods-response';
import {
  CustomButton,
  CustomTable,
  CustomTabs,
  CustomText,
} from '../../../../components';

interface GoodsDetailViewProps {
  detailData?: GoodsRequest;
  setFooterLeft: (footer: React.ReactNode) => void;
  onVoucher?: () => void;
  onDocuments: (id: string[]) => void;
}

const GoodsDetailView: React.FC<GoodsDetailViewProps> = ({
  detailData,
  setFooterLeft,
  onVoucher,
  onDocuments,
}) => {
  // FOOTER
  React.useEffect(() => {
    setFooterLeft(
      <Fragment>
        {/* <CustomButton
          label={'Generar comprobante'}
          onClick={onVoucher}
          icon={<FileProtectOutlined />}
          size="middle"
          noBorder
          buttonType={'secondary'}
        /> */}
        <CustomButton
          label={'Descargar documentos'}
          disabled={detailData?.documents && detailData.documents.length === 0}
          onClick={() => {
            const documentIds = detailData?.documents
              ?.map((doc) => doc.id)
              .filter((id): id is string => id !== undefined);
            onDocuments(documentIds || []);
          }}
          icon={<DownloadOutlined />}
          size="middle"
          buttonType={'secondary'}
        />
      </Fragment>
    );
  }, [setFooterLeft]);

  const documentColumns = [
    {
      title: 'Item',
      dataIndex: 'index',
      key: 'index',
      render: (_: any, __: any, index: number) => index + 1,
    },
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Fecha creación',
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
    {
      title: 'Archivo',
      dataIndex: 'link',
      key: 'link',
      render: (link: string) => (
        <Button
          type="link"
          href={link}
          target="_blank"
          icon={<DownloadOutlined />}
        />
      ),
    },
  ];

  return (
    <Fragment>
      <CustomTabs
        defaultActiveKey="1"
        items={[
          {
            key: '1',
            label: (
              <Button type="link" icon={<FileTextOutlined />}>
                Información General
              </Button>
            ),
            children: (
              <Fragment>
                <Row gutter={[16, 16]} style={{ marginBottom: '20px' }}>
                  <Col span={8}>
                    <div className="mb-4 mt-8">
                      <CustomText text="Solicitante:" strong fontSize="small" />
                      <CustomText
                        text={detailData?.requester}
                        fontSize="middle"
                      />
                    </div>
                  </Col>
                  <Col span={8}>
                    <div className="mb-4 mt-8">
                      <CustomText
                        text="Tipo de solicitud:"
                        strong
                        fontSize="small"
                      />
                      <CustomText
                        text={detailData?.request_type}
                        fontSize="middle"
                      />
                    </div>
                  </Col>
                  <Col span={8}>
                    <div className="mb-4 mt-8">
                      <CustomText text="Dependencia:" strong fontSize="small" />
                      <CustomText
                        text={detailData?.dependency}
                        fontSize="middle"
                      />
                    </div>
                  </Col>
                  <Col span={8}>
                    <div className="mb-4 mt-8">
                      <CustomText text="Dependencia:" strong fontSize="small" />
                      <CustomText
                        text={detailData?.location}
                        fontSize="middle"
                      />
                    </div>
                  </Col>
                  <Col span={8}>
                    <div className="mb-4 mt-8">
                      <CustomText text="Fecha:" strong fontSize="small" />
                      <CustomText
                        text={detailData?.query_date}
                        fontSize="middle"
                      />
                    </div>
                  </Col>
                  <Col span={8}>
                    <div className="mb-4 mt-8">
                      <CustomText
                        text="Estado de solicitud:"
                        strong
                        fontSize="small"
                      />
                      <CustomText text={detailData?.status} fontSize="middle" />
                    </div>
                  </Col>
                </Row>
              </Fragment>
            ),
          },
          {
            key: '2',
            label: (
              <Button type="link" icon={<FileSearchOutlined />}>
                Documentos
              </Button>
            ),
            children: (
              <>
                <CustomTable
                  dataSource={detailData?.documents?.map((doc, index) => ({
                    key: index,
                    createdAt: doc.created_at,
                    name: doc.url?.split('/').pop(),
                    link: doc.url,
                  }))}
                  columns={documentColumns}
                  pagination={false}
                />
              </>
            ),
          },
          {
            key: '3',
            label: (
              <Button type="link" icon={<HistoryOutlined />}>
                Historial de la Solicitud
              </Button>
            ),
            children: (
              <CustomTable
                columns={[
                  {
                    title: 'Id',
                    dataIndex: 'index',
                    key: 'index',
                    render: (_: any, __: any, index: number) => index + 1,
                  },
                  {
                    title: 'Fecha de Solicitud',
                    dataIndex: 'date',
                    key: 'date',
                  },
                  {
                    title: 'Estado',
                    dataIndex: 'comment',
                    key: 'comment',
                  },
                ]}
                dataSource={detailData?.history?.map((item, index) => ({
                  key: index,
                  date: item.date,
                  comment: item.comment,
                }))}
                pagination={false}
              />
            ),
          },
        ]}
      />
    </Fragment>
  );
};

export default GoodsDetailView;
