import { useQuery } from '@tanstack/react-query';
import { ShareAPI, ShareResponse } from '..';
import { LocationResponse } from '../types/location-response';
import { ItemResponse } from '../types/item-response';
import { AssetsResponse } from '../types/asset-response';

interface ThirdParty {
  id: number;
  name: string;
}

interface ListTercerosResponse {
  third_party: ThirdParty[];
}
export const useEntryManage = (id: number, token: string) => {
  return useQuery<any[], Error>({
    queryKey: ['entryManage'],
    queryFn: () => ShareAPI.entryManage(id, token),
  });
};
export const useListTerceros = (token: string) => {
  console.log("TOKEN", token)
  return useQuery<ListTercerosResponse, Error>({
    queryKey: ['listTerceros'],
    queryFn: () => ShareAPI.listTerceros(token),
  });
};
export const useEntyTypes = () => {
  return useQuery<ShareResponse[], Error>({
    queryKey: ['entryTypes'],
    queryFn: ShareAPI.entryTypes,
  });
};
export const useItemTypes = () => {
  return useQuery<ShareResponse[], Error>({
    queryKey: ['itemTypes'],
    queryFn: ShareAPI.itemTypes,
  });
};

export const useDeliveryOptions = () => {
  return useQuery<ShareResponse[], Error>({
    queryKey: ['deliveryOptions'],
    queryFn: ShareAPI.deliveryOptions,
  });
};

export const useEntryStates = () => {
  return useQuery<ShareResponse[], Error>({
    queryKey: ['entryStates'],
    queryFn: ShareAPI.entryStates,
  });
};

export const useGoodStates = () => {
  return useQuery<ShareResponse[], Error>({
    queryKey: ['goodStates'],
    queryFn: ShareAPI.goodstates,
  });
};

export const useAreas = () => {
  return useQuery<ShareResponse[], Error>({
    queryKey: ['areas'],
    queryFn: ShareAPI.areas,
  });
};

export const useSedes = () => {
  return useQuery<ShareResponse[], Error>({
    queryKey: ['sedes'],
    queryFn: ShareAPI.sedes,
  });
};

export const useSupervisors = () => {
  return useQuery<ShareResponse[], Error>({
    queryKey: ['supervisors'],
    queryFn: ShareAPI.supervisors,
  });
};

export const useDependecies = () => {
  return useQuery<ShareResponse[], Error>({
    queryKey: ['dependencies'],
    queryFn: ShareAPI.dependencies,
  });
};

export const useAssets = () => {
  return useQuery<AssetsResponse[], Error>({
    queryKey: ['assets'],
    queryFn: ShareAPI.assets,
  });
};

export const useLocation = () => {
  return useQuery<LocationResponse[], Error>({
    queryKey: ['location'],
    queryFn: ShareAPI.location,
  });
};

export const useItem = () => {
  return useQuery<ItemResponse[], Error>({
    queryKey: ['item'],
    queryFn: ShareAPI.item,
  });
};
