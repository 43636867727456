import React, { useState } from 'react';
import { Tabs, Button } from 'antd';

interface CustomTabsProps {
  items: Array<{
    key: string;
    label: React.ReactNode;
    children: React.ReactNode;
  }>;
  defaultActiveKey?: string;
}

const CustomTabs: React.FC<CustomTabsProps> = ({ items, defaultActiveKey }) => {
  const [activeTab, setActiveTab] = useState(defaultActiveKey || items[0]?.key);

  return (
    <Tabs
      activeKey={activeTab}
      indicator={{ size: 0 }}
      onChange={(key) => setActiveTab(key)}
      items={items?.map((item) => ({
        ...item,
        label: (
          <Button
            type="link"
            className={`tab-button ${activeTab === item.key ? 'active' : ''}`}
          >
            {item.label}
          </Button>
        ),
      }))}
    />
  );
};

export default CustomTabs;
