import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { QueryProvider } from './providers/QueryProvider';
import { StateProvider } from './providers/StateProvider';
import PublicRoutes from './routes/PublicRoutes';
import PrivateRoutes from './routes/PrivateRoutes';
import 'antd/dist/reset.css';
import './styles/custom-components.css';
import './styles/globals.css';
import { ToastContainer } from 'react-toastify';
import { ConfigProvider } from 'antd';
import esES from 'antd/lib/locale/es_ES';

const App: React.FC = () => {
  return (
    <ConfigProvider
      locale={esES}
      theme={{
        token: {
          colorPrimaryBorder: 'black',
          colorPrimary: '#102486',
          colorText: '#333742',
          colorBorder: '#DCDCDC',
          colorIcon: '#102486',
          colorIconHover: '#102486',
          colorLink: '#102486',
        },
      }}
    >
      <QueryProvider>
        <StateProvider>
          <ToastContainer />
          <Router>
            {/* Rutas públicas */}
            <PublicRoutes />
            {/* Rutas privadas */}
            <PrivateRoutes />
          </Router>
        </StateProvider>
      </QueryProvider>
    </ConfigProvider>
  );
};

export default App;
