import React from 'react';
import {
  CustomModal,
  CustomTable,
  FormInput,
  FormSelect,
  GeneralContainer,
} from '../../../../components';
import { Button, Col, Row } from 'antd';
import { FileDoneOutlined, ProfileOutlined } from '@ant-design/icons';
import type { ShareResponse } from '../../../../share';

interface Data {
  item: string;
  seccional: string;
  factura: string;
  cantidad: string;
}

interface ModalSeccionalProps {
  isOpen: boolean;
  formik: any;
  items: Data[];
  currentPage: number;
  sedes: ShareResponse[];
  totalCantidad: number;
  onPagination: (page: number) => void;
  onReset: () => void;
  handleDelete: (record: Data) => void;
  setIsOpen: (value: boolean) => void;
}

const PAGE_SIZE = 8;

const ModalSeccional = ({
  isOpen,
  formik,
  items,
  currentPage,
  sedes,
  totalCantidad,
  onReset,
  onPagination,
  handleDelete,
  setIsOpen,
}: ModalSeccionalProps) => {
  const columns = React.useMemo(
    () => [
      {
        title: 'Item',
        dataIndex: 'item',
        key: 'item',
        align: 'center',
      },
      {
        title: 'Seccional',
        dataIndex: 'seccional',
        key: 'seccional',
        align: 'center',
      },
      {
        title: 'Factura',
        dataIndex: 'factura',
        key: 'factura',
        align: 'center',
      },
      {
        title: 'Cantidad',
        dataIndex: 'cantidad',
        key: 'cantidad',
        align: 'center',
      },
      {
        title: 'Acciones',
        dataIndex: 'acciones',
        key: 'acciones',
        align: 'center',
        render: (_: any, record: Data) => (
          <Button
            type="default"
            style={{ backgroundColor: 'red', color: 'white' }}
            onClick={() => handleDelete(record)}
          >
            Eliminar
          </Button>
        ),
      },
    ],
    [items]
  );

  return (
    <CustomModal
      title="Añadir Seccionales"
      open={isOpen}
      onCancel={() => {
        onReset();
      }}
      width={900}
      isLoadingSave={false}
      onClose={() => {
        onReset();
      }}
      labelSubmit="Aceptar"
      onSave={() => setIsOpen(false)}
    >
      <Row gutter={[16, 16]} style={{ marginBottom: '20px' }}>
        <Col xs={24} sm={12} md={8} lg={8}>
          <FormSelect
            label="Seccional entrada"
            name="section_id"
            placeholder="Seleccione"
            value={formik.values.section_id}
            options={sedes}
            error={formik.errors.section_id}
            touched={formik.touched.section_id}
            onChange={(value: string) => {
              const option = sedes?.find((sede) => sede.id === value);
              formik.setFieldValue('section_id', value);
              formik.setFieldValue('section_label', option?.name);
            }}
            onBlur={() => formik.setFieldTouched('section_id', true)}
          />
        </Col>
        <Col xs={24} sm={12} md={8} lg={8}>
          <FormInput
            label="Número de factura"
            name="invoice_number"
            value={formik.values.invoice_number}
            error={formik.errors.invoice_number}
            touched={formik.touched.invoice_number}
            onChange={(e) =>
              formik.setFieldValue('invoice_number', e.target.value)
            }
            onBlur={() => formik.setFieldTouched('invoice_number', true)}
          />
        </Col>
        <Col xs={24} sm={12} md={8} lg={8}>
          <FormInput
            label="Cantidad"
            name="deliveries"
            placeholder="Seleccione"
            type="number"
            value={formik.values.deliveries?.toString()}
            error={formik.errors.deliveries}
            touched={formik.touched.deliveries}
            onChange={(e) => {
              const value = e.target.value === '' ? 0 : Number(e.target.value);
              formik.setFieldValue('deliveries', value);
            }}
            onBlur={formik.handleBlur}
          />
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ marginBottom: '20px' }}>
        <Col xs={24} sm={12} md={8} lg={6}>
          <Button type="default" icon={<ProfileOutlined />}>
            {`${totalCantidad} Entregas totales`}
          </Button>
        </Col>

        <Col xs={24} sm={12} md={8} lg={6}>
          <Button type="default" icon={<FileDoneOutlined />}>
            {`${items.length} Total facturas`}
          </Button>
        </Col>
      </Row>

      <GeneralContainer
        title="Seccionales de entrega"
        actionLabel="Añadir"
        onActionClick={() => {
          formik.handleSubmit();
        }}
      >
        <Row>
          <Col xs={24}>
            <CustomTable
              columns={columns as any}
              dataSource={items}
              paginationConfig={{
                current: currentPage,
                pageSize: PAGE_SIZE,
                total: items.length,
                onChange: onPagination,
              }}
              scroll={{ x: 'max-content' }}
            />
          </Col>
        </Row>
      </GeneralContainer>
    </CustomModal>
  );
};

export default ModalSeccional;