import React, { Fragment } from 'react';
import { Row, Col, Button } from 'antd';
import {
  FileTextOutlined,
  FileSearchOutlined,
  HistoryOutlined,
  DownloadOutlined,
} from '@ant-design/icons';
import { EntryRequest } from '../../types/entry-response';
import {
  CustomButton,
  CustomTable,
  CustomTabs,
  CustomText,
} from '../../../../components';

interface EntryRequestDetailViewProps {
  detailData?: EntryRequest;
  setFooterLeft: (footer: React.ReactNode) => void;
  onVoucher?: () => void;
  onDocuments: (id: string[]) => void;
}

const EntryRequestDetailView: React.FC<EntryRequestDetailViewProps> = ({
  detailData,
  setFooterLeft,
  onDocuments,
}) => {
  React.useEffect(() => {
    console.log('detailData', detailData)
    setFooterLeft(
      <CustomButton
        label="Descargar documentos"
        disabled={!!detailData?.documents && detailData.documents.length === 0}
        onClick={() => {
          const documentIds = detailData?.documents
            ?.map((doc) => doc.id)
            .filter((id): id is string => id !== undefined);
          onDocuments(documentIds || []);
        }}
        icon={<DownloadOutlined />}
        size="middle"
        buttonType="secondary"
      />
    );
  }, [setFooterLeft]);

  const documentColumns = [
    {
      title: 'Item',
      dataIndex: 'index',
      key: 'index',
      render: (_: any, __: any, index: number) => index + 1,
    },
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Fecha creación',
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
    {
      title: 'Archivo',
      dataIndex: 'link',
      key: 'link',
      render: (link: string) => (
        <Button
          type="link"
          href={link}
          target="_blank"
          icon={<DownloadOutlined />}
        />
      ),
    },
  ];

  return (
    <Fragment>
      <CustomTabs
        defaultActiveKey="1"
        items={[
          {
            key: '1',
            label: (
              <Button type="link" icon={<FileTextOutlined />}>
                Información General
              </Button>
            ),
            children: (
              <Row gutter={[16, 16]} style={{ marginBottom: '20px' }}>
                <Col span={8}>
                  <div className="mb-4 mt-8">
                    <CustomText text="Solicitante:" strong fontSize="small" />
                    <CustomText
                      text={detailData?.requester}
                      fontSize="middle"
                    />
                  </div>
                  <div className="mb-4 mt-8">
                    <CustomText text="Dependencia:" strong fontSize="small" />
                    <CustomText
                      text={detailData?.dependency}
                      fontSize="middle"
                    />
                  </div>
                  <div className="mb-4 mt-8">
                    <CustomText text="Factura:" strong fontSize="small" />
                    <CustomText
                      text={detailData?.invoice_number}
                      fontSize="middle"
                    />
                  </div>
                  <div className="mb-4 mt-8">
                    <CustomText text="Fecha Factura:" strong fontSize="small" />
                    <CustomText
                      text={detailData?.invoice_date}
                      fontSize="middle"
                    />
                  </div>
                </Col>
                <Col span={8}>
                  <div className="mb-4 mt-8">
                    <CustomText
                      text="Tipo de Entrada:"
                      strong
                      fontSize="small"
                    />
                    <CustomText
                      text={detailData?.entry_type}
                      fontSize="middle"
                    />
                  </div>
                  <div className="mb-4 mt-8">
                    <CustomText text="Tipo de Bien:" strong fontSize="small" />
                    <CustomText
                      text={detailData?.item_type}
                      fontSize="middle"
                    />
                  </div>
                  <div className="mb-4 mt-8">
                    <CustomText
                      text="Orden de Compra:"
                      strong
                      fontSize="small"
                    />
                    <CustomText
                      text={detailData?.purchase_order}
                      fontSize="middle"
                    />
                  </div>
                  <div className="mb-4 mt-8">
                    <CustomText
                      text="Entregas Estipuladas:"
                      strong
                      fontSize="small"
                    />
                    <CustomText
                      text={
                        detailData?.deliveries
                          ? detailData?.deliveries.toString()
                          : 'No especificado'
                      }
                      fontSize="middle"
                    />
                  </div>
                </Col>
                <Col span={8}>
                  <div className="mb-4 mt-8">
                    <CustomText text="Seccional:" strong fontSize="small" />
                    <CustomText text={detailData?.section} fontSize="middle" />
                  </div>
                  <div className="mb-4 mt-8">
                    <CustomText text="Observaciones:" strong fontSize="small" />
                    <CustomText
                      text={detailData?.observations}
                      fontSize="middle"
                    />
                  </div>
                  <div className="mb-4 mt-8">
                    <CustomText text="Usuario que atiende la solicitud:" strong fontSize="small" />
                    <CustomText
                      text={detailData?.manager}
                      fontSize="middle"
                    />
                  </div>
                </Col>
              </Row>
            ),
          },
          {
            key: '2',
            label: (
              <Button type="link" icon={<FileSearchOutlined />}>
                Documentos
              </Button>
            ),
            children: (
              <>
                <CustomTable
                  dataSource={detailData?.documents?.map((doc, index) => ({
                    key: index,
                    createdAt: doc.created_at,
                    name: doc.url?.split('/').pop(),
                    link: doc.url,
                  }))}
                  columns={documentColumns}
                  pagination={false}
                />
              </>
            ),
          },
          {
            key: '3',
            label: (
              <Button type="link" icon={<HistoryOutlined />}>
                Historial de la Solicitud
              </Button>
            ),
            children: (
              <CustomTable
                columns={[
                  {
                    title: 'Item',
                    dataIndex: 'index',
                    key: 'index',
                    render: (_: any, __: any, index: number) => index + 1,
                  },
                  {
                    title: 'Fecha de Solicitud',
                    dataIndex: 'date',
                    key: 'date',
                  },
                  {
                    title: 'Estado',
                    dataIndex: 'comment',
                    key: 'comment',
                  },
                ]}
                dataSource={detailData?.history?.map((item, index) => ({
                  key: index,
                  date: item.date,
                  comment: item.comment,
                }))}
                pagination={false}
              />
            ),
          },
        ]}
      />
    </Fragment>
  );
};

export default EntryRequestDetailView;
