import { useFormik } from "formik";
import * as Yup from 'yup';
import { RefundsRequest } from "../../../modules/Refunds/types/refunds-response";

// Esquema de validación
export const refundRequestValidationSchema = Yup.object({
    dependency_id: Yup.string().required('Requerido'),
    location_id: Yup.string().required('Requerido'),
    new_responsible_id: Yup.string().required('Requerido'),
    new_dependency_id: Yup.string().required('Requerido'),
    status_id: Yup.string().required('Requerido'),
    asset_class: Yup.string(),
    item: Yup.string(),
    name: Yup.string(),
    plate_code: Yup.string(),
  });
  
  // Hook customizado
  interface UseRefundRequestFormikProps {
    onSubmit: (values: Partial<RefundsRequest>) => void;
  }
  
  const useRefundRequestFormik = ({ onSubmit }: UseRefundRequestFormikProps) => {
    return useFormik<Partial<RefundsRequest>>({
      initialValues: {
        dependency_id: '',
        location_id: '',
        new_responsible_id: '',
        new_dependency_id: '',
        status_id: '',
      },
      validationSchema: refundRequestValidationSchema,
      onSubmit,
    });
  };
  
  export default useRefundRequestFormik;