import React from 'react';
import { CustomModal } from '../../../../components';
import { RefundsRequest } from '../../types/refunds-response';
import { useAtom } from 'jotai';
import { useCreateRefund } from '../../../../hooks';
import { useEntyTypes, useItemTypes, useEntryStates, useGoodStates, useDeliveryOptions, useSedes, useSupervisors, useDependecies, useAssets, useLocation } from '../../../../share';
import { authAtom } from '../../../../state';
import { UploadedFile } from '../../../../utils';
import RefundCreateView from '../Views/RefundsCreateView';
import useRefundRequestFormik from '../../../../utils/validators/refund-request/refund-request-validator';
import { AssetsResponse } from '../../../../share/types/asset-response';

interface RefundsCreateProps {
  isOpen: boolean;
  OnClose: () => void;
}

const RefundsCreateContainer: React.FC<RefundsCreateProps> = ({
  isOpen,
  OnClose,
}) => {
  const [files, setFiles] = React.useState<UploadedFile[]>();
  const [assets, setAssets] = React.useState<AssetsResponse[]>([]);
  
  const [authData] = useAtom(authAtom);
  const { mutateAsync, isPending: loadCreate } = useCreateRefund();
  const { data: entyTypes } = useEntyTypes();
  const { data: itemTypes } = useItemTypes();
  const { data: entryStates } = useEntryStates();
  const { data: goodStates } = useGoodStates();
  const { data: entryDevelivery } = useDeliveryOptions();

  const { data: sedes } = useSedes();
  const { data: supervisors } = useSupervisors();
  const { data: dependencies } = useDependecies();
  const { data: assetList } = useAssets();
  const { data: location } = useLocation();

  const onSave = async (values: Partial<RefundsRequest>) => {    
    const payload: Partial<RefundsRequest> = {
      requester_id: authData?.user.id.toString(),
      dependency_id: values.dependency_id,
      location_id: values.location_id,
      new_responsible_id: values.new_responsible_id,
      new_dependency_id: values.new_dependency_id,
      documents: files,
      items: assets?.map((value) => ({ asset_id: value.id.toString() }))
    };

    await mutateAsync({
      ...payload as RefundsRequest
    });
    onReset();
  };

  const formik = useRefundRequestFormik({ onSubmit: onSave });

  const handleSubmit = () => {
    formik.handleSubmit();
  };

  const onReset = () => {
    formik.resetForm();
    OnClose();
    setFiles([]);
  };

  const handleAddAsset = (item: AssetsResponse[]) => {
    setAssets([...item]);
  };

  const handleDeleteAsset = (req: number) => {};

  return (
    <CustomModal
      title="Nueva solicitud de reintegro"
      labelSubmit="Guardar solicitud"
      open={isOpen}
      onCancel={() => {
        onReset();
      }}
      width={800}
      isLoadingSave={loadCreate}
      onSave={handleSubmit}
      onClose={() => {
        onReset();
      }}
    >
      <RefundCreateView
        entyTypes={entyTypes}
        itemTypes={itemTypes}
        entryDevelivery={entryDevelivery}
        entryStates={entryStates}
        goodStates={goodStates}
        authData={authData}
        sedes={sedes}
        supervisors={supervisors}
        dependencies={dependencies}
        formik={formik}
        onSave={handleSubmit}
        onCancel={onReset}
        onFiles={setFiles}
        files={files}
        assets={assetList}
        location={location}
        onAdd={handleAddAsset}
        onDeleteAsset={handleDeleteAsset}

      />
    </CustomModal>
  );
};

export default RefundsCreateContainer;
