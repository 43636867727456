import React from 'react';
import { CustomModal } from '../../../../components';
import { TransfersRequest } from '../../types/transfers-response';
import { useAtom } from 'jotai';
import { useCreateTransfer } from '../../../../hooks';
import { useEntyTypes, useItemTypes, useEntryStates, useGoodStates, useDeliveryOptions, useSedes, useSupervisors, useDependecies, useAssets, useLocation } from '../../../../share';
import { authAtom } from '../../../../state';
import { UploadedFile } from '../../../../utils';
import TransferCreateView from '../Views/TransfersCreateView';
import { AssetsResponse } from '../../../../share/types/asset-response';
import useTransferRequestFormik from '../../../../utils/validators/transfer-request/transfer-request-validator';

interface TransfersCreateProps {
  isOpen: boolean;
  OnClose: () => void;
}

const TransfersCreateContainer: React.FC<TransfersCreateProps> = ({
  isOpen,
  OnClose,
}) => {
  const [files, setFiles] = React.useState<UploadedFile[]>();
  const [asset, setAsset] = React.useState<AssetsResponse[]>([]);
  const [authData] = useAtom(authAtom);
  const { mutateAsync, isPending: loadCreate } = useCreateTransfer();
  const { data: entyTypes } = useEntyTypes();
  const { data: itemTypes } = useItemTypes();
  const { data: entryStates } = useEntryStates();
  const { data: goodStates } = useGoodStates();
  const { data: entryDevelivery } = useDeliveryOptions();

  const { data: sedes } = useSedes();
  const { data: supervisors } = useSupervisors();
  const { data: dependencies } = useDependecies();
  const { data: assetList } = useAssets();
  const { data: location } = useLocation();

  const onSave = async (values: Partial<TransfersRequest>) => {
    const payload: Partial<TransfersRequest> = {
      dependency_id: values.dependency_id,
      new_dependency_id: values.new_dependency_id,
      new_responsible_id: values.new_responsible_id,
      documents: files,
      items: asset?.map((value) => ({ asset_id: value.id }))
    };

    await mutateAsync({
      ...payload as TransfersRequest
    });
    onReset();
  };

  const formik = useTransferRequestFormik({ onSubmit: onSave });

  const handleSubmit = () => {
    formik.handleSubmit();
  };

  const onReset = () => {
    formik.resetForm();
    OnClose();
    setAsset([]);
    setFiles([]);
  };

  const handleAddAsset = (item: AssetsResponse) => {    
    onResetAsset();
    setAsset((prev) => [ 
      ...(prev || []),
        item
    ]);
  };

  const onResetAsset = () => {
    formik.setFieldValue('asset_class', '');
    formik.setFieldValue('item', '');
    formik.setFieldValue('name', '');
    formik.setFieldValue('plate_code', '');
  };

  const handleDeleteAsset = (req: number) => {
    const updatedAssets = asset?.filter((_, index) => index !== req);
    setAsset(updatedAssets);
    onResetAsset();
  };

  return (
    <CustomModal
      title="Nueva solicitud de traslado de funcionarios"
      labelSubmit="Guardar solicitud"
      open={isOpen}
      onCancel={() => {
        onReset();
      }}
      width={800}
      isLoadingSave={loadCreate}
      onSave={handleSubmit}
      onClose={() => {
        onReset();
      }}
    >
      <TransferCreateView
        entyTypes={entyTypes}
        itemTypes={itemTypes}
        entryDevelivery={entryDevelivery}
        entryStates={entryStates}
        goodStates={goodStates}
        authData={authData}
        sedes={sedes}
        supervisors={supervisors}
        dependencies={dependencies}
        formik={formik}
        onSave={handleSubmit}
        onCancel={onReset}
        onFiles={setFiles}
        files={files}
        assets={assetList}
        location={location}
        assetAdded={asset}
        onAdd={handleAddAsset}
        onDeleteAsset={handleDeleteAsset}

      />
    </CustomModal>
  );
};

export default TransfersCreateContainer;
