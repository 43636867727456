import React, { Fragment } from 'react';
import { ModalType, useFormikTransferFilters } from '../../../../utils';
import TransfersView from '../Views/TransfersView';
import { TransfersFilter } from '../../types/transfers-response';
import { useFetchTransferRequests, useFetchTransferById } from '../../../../hooks';
import { useGoodStates } from '../../../../share';
import TransfersCreateContainer from './TransfersCreateContainer';
import TransfersDetailContainer from './TransfersDetailContainer';

const TransfersContainer: React.FC = () => {
  const [filter, setFilter] = React.useState<TransfersFilter>();
  const { data: transferStates } = useGoodStates();
  //manage state modal
  const [modalState, setModalState] = React.useState<{
    type: ModalType;
    id?: string;
  }>({ type: null, id: undefined });
  const {
    data: tranfers,
    isPending: loadList,
    refetch,
  } = useFetchTransferRequests(filter);
  const { data: transfer, isPending: loadById } = useFetchTransferById(modalState.id);

  const handleModalToggle = (type: ModalType, id?: string) => {
    setModalState((prev) => ({
      type: prev.type === type ? null : type,
      id: id,
    }));
  };

  const handlePagination = (page: number) => {
    setFilter({
      page: page.toString(),
    });
  };

  const handleFilter = async (values: TransfersFilter) => {
    setFilter({
      ...values,
      created_at: values?.created_at
        ? new Date(values.created_at).toISOString().split('T')[0]
        : '',
    });
    refetch();
  };

  const formik = useFormikTransferFilters({ onSubmit: handleFilter });
  
  return (
    <Fragment>
      <TransfersCreateContainer
        isOpen={modalState.type === 'create'}
        OnClose={() => handleModalToggle('create')}
      />
      
      <TransfersDetailContainer
        isOpen={modalState.type === 'detail'}
        OnClose={() => handleModalToggle('detail')}
        data={transfer}
        isLoading={loadById}
      />
      
      <TransfersView
        isLoading={loadList}
        data={tranfers}
        states={transferStates}
        formik={formik}
        onViewCreate={() => handleModalToggle('create')}
        onViewDetail={(id) => handleModalToggle('detail', id)}
        onPagination={handlePagination}
        onResetFilters={() => {
          handleFilter({});
          formik.resetForm();
        }}
      />
    </Fragment>
  );
};

export default TransfersContainer;
