import { useFormik } from 'formik';
import * as Yup from 'yup';

interface UseFormikTransferFiltersProps {
  onSubmit: (values: any) => void;
}

const useFormikTransferFilters = ({ onSubmit }: UseFormikTransferFiltersProps) => {
  const initialValues = {
    ticket: '',
    status_id: '',
    created_at: '',
  };

  const validationSchema = Yup.object({
    ticket: Yup.string(),
    status_id: Yup.string(),
    created_at: Yup.date().nullable(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, actions) => {
      onSubmit(values);
      actions.setSubmitting(false);
    },
  });

  return formik;
};

export default useFormikTransferFilters;
