import React, { useEffect } from 'react';
import {
  Modal as AntModal,
  ModalProps as AntModalProps,
  Button,
  Row,
  Col,
} from 'antd';
import CustomLabel from '../Label/CustomLabel';
import CustomButton from '../Button/Button';

interface ModalProps extends AntModalProps {
  title: string;
  footerLeft?: React.ReactNode;
  onCancel: () => void;
  onSave?: (data: any) => void;
  labelSubmit?: string;
  isLoading?: boolean;
  isLoadingSave?: boolean;
}


const CustomModal: React.FC<ModalProps> = ({
  title,
  children,
  footerLeft,
  onCancel,
  onSave,
  labelSubmit,
  isLoading = false,
  isLoadingSave = false,
  ...props
}) => {
  return (
    <AntModal
      loading={isLoading}
      {...props}
      closeIcon={
        <span className="custom-close-icon" onClick={() => onCancel()}>
          x
        </span>
      }
      title={
        <div className="custom-modal-title-container mb-32">
          <CustomLabel text={title} level={4} align="left" />
        </div>
      }
      footer={
        <Row justify="space-between" align="middle">
          <Col>{footerLeft}</Col>
          <Col>
            {!footerLeft && labelSubmit && (
              <CustomButton
                label={labelSubmit}
                loading={isLoadingSave}
                onClick={onSave}
                size="middle"
              />
            )}
            <CustomButton
              buttonType={'secondary'}
              label={'Cancelar'}
              onClick={onCancel}
              size="middle"
            />
          </Col>
        </Row>
      }
    >
      {children}
    </AntModal>
  )
}

export default CustomModal