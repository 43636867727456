import React from 'react';
import { useCustomToast } from '../../components';
import type { EntrySeccionales } from '../../modules/EntryRequests/types';
import { useEntrySeccionales } from './useEntrySeccionales';

interface Data {
  item: string;
  seccional: string;
  factura: string;
  cantidad: string;
}

export const useModalSeccional = (maxDeliveries: number) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [data, setData] = React.useState<Data[]>([]);
  const [currentPage, setCurrentPage] = React.useState(1);
  const { showToast } = useCustomToast();

  const totalCantidad = React.useMemo(() => {
    return data.reduce((sum, item) => sum + Number(item.cantidad), 0);
  }, [data]);

  const validateMaxCantidad = (newCantidad: number) => {
    if (totalCantidad + newCantidad > maxDeliveries) {
      showToast('error', 'Superaste la cantidad máxima');
      return false;
    }
    return true;
  };

  const getNextAvailableIndex = (prevData: Data[]): number => {
    const usedIndices = prevData
      .map((item) => parseInt(item.item.replace('item ', '')))
      .sort((a, b) => a - b);

    let nextIndex = 1;
    while (usedIndices.includes(nextIndex)) {
      nextIndex++;
    }
    return nextIndex;
  };

  const createNewItem = (values: EntrySeccionales, prevData: Data[]) => {
    const nextIndex = getNextAvailableIndex(prevData);
    return {
      item: `item ${nextIndex}`,
      seccional: values.section_id || '',
      factura: values.invoice_number ?? '',
      cantidad: values.deliveries?.toString() || '',
    };
  };

  const handleDelete = (record: Data) => {
    const indexTable = record.item;
    setData((prevData) =>
      prevData.filter((item) => {
        return item.item !== indexTable;
      })
    );
  };

  const handlePagination = (page: number) => {
    setCurrentPage(page);
  };

  const onReset = () => {
    setIsOpen(false);
    setData([]);
  };

  const handleSubmit = (data: EntrySeccionales) => {
    onSave(data);
  };

  const onSave = (values: EntrySeccionales) => {
    const { deliveries = 0 } = values;

    if (!validateMaxCantidad(deliveries)) {
      return;
    }

    setData((prev) => [...(prev || []), createNewItem(values, prev)]);
    formik.resetForm();
  };

  const formik = useEntrySeccionales({ onSubmit: handleSubmit });

  return {
    data,
    isOpen,
    formik,
    currentPage,
    totalCantidad,
    setIsOpen,
    onReset,
    handleSubmit,
    handleDelete,
    handlePagination,
  };
};
