import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Row, Col, Typography, Divider, Button } from 'antd';
import {
  CustomText,
  CustomTextArea,
  FormDatePicker,
  FormInput,
  FormSelect,
  UploadWithTable,
} from '../../../../components';
import {
  formatNumber,
  handleInvoiceValueChange,
  UploadedFile,
} from '../../../../utils';
import { AuthResponse } from '../../../Auth/types';
import { ShareResponse, useListTerceros } from '../../../../share';
import { DateTime } from 'luxon';
import { ENTRY_TYPE } from '../../types';
import FormSelectSearch from '../../../../components/FormSelectSearch/FormSelectSearch';



const { Title } = Typography;

interface EntryRequestCreateViewProps {
  formik: any;
  entyTypes?: ShareResponse[];
  itemTypes?: ShareResponse[];
  entryStates?: ShareResponse[];
  goodStates?: ShareResponse[];
  entryDevelivery?: ShareResponse[];
  sedes?: ShareResponse[];
  dependencies?: ShareResponse[];
  supervisors?: ShareResponse[];
  onSave: () => void;
  onCancel: () => void;
  authData?: AuthResponse | null;
  onFiles: (files: UploadedFile[]) => void;
  setIsOpen?: (value: boolean) => void;
  files?: UploadedFile[];
}

const EntryRequestCreateView: React.FC<EntryRequestCreateViewProps> = ({
  formik,
  entyTypes,
  itemTypes,
  entryDevelivery,
  entryStates,
  sedes,
  supervisors,
  authData,
  files,
  onFiles,
  setIsOpen,
  dependencies,
}) => {
  const storedToken = localStorage.getItem('auth');
  const parsedToken = storedToken ? JSON.parse(storedToken) : null;
  const token = parsedToken?.authorization?.token;
  const { data, isLoading, error } = useListTerceros(token);
  const [fechaPorDefecto, setFechaPorDefecto]: any = useState('')
  const terceros = data?.third_party || [];

  useEffect(() => {
  }, [])

  const handleDate = () => {
    setFechaPorDefecto('')
  }
  return (
    <>
      <Row gutter={[16, 16]} style={{ marginBottom: '20px' }}>
        <Col xs={24} sm={12} md={8} lg={6}>
          <FormInput
            label="Solicitante"
            name="requester_id"
            placeholder="Solicitante"
            value={`${authData?.user.name} ${authData?.user.last_name} - ${authData?.user.document}`}
            disabled
            error={formik.errors.requester_id}
            touched={formik.touched.requester_id}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Col>
        <Col xs={24} sm={12} md={8} lg={6}>
          <FormSelect
            label="Tipo de entrada"
            name="entry_type"
            placeholder="Seleccione"
            value={formik.values.entry_type}
            options={entyTypes}
            error={formik.errors.entry_type}
            touched={formik.touched.entry_type}
            onChange={(value) => {
              // setEntryType(VALUE)
              console.log('value', value);
              formik.setFieldValue('entry_type', value);
            }}
            onBlur={formik.handleBlur}
          />
        </Col>
        <Col xs={24} sm={12} md={8} lg={6}>
          <FormInput
            label="Cantidad Entregas Estipuladas"
            name="deliveries"
            placeholder="Seleccione"
            type="number"
            disabled={formik.values.item_type == 1}
            value={formik.values.deliveries}
            error={formik.errors.deliveries}
            touched={formik.touched.deliveries}
            onChange={(e) => {
              const value = e.target.value === '' ? 0 : Number(e.target.value);
              formik.setFieldValue('deliveries', value);
            }}
            onBlur={formik.handleBlur}
          />
        </Col>
        <Col xs={24} sm={12} md={8} lg={6}>
          <FormSelect
            label="Seccional entrada"
            name="section_id"
            placeholder="Seleccione"
            value={formik.values.section_id}
            options={sedes}
            error={formik.errors.section_id}
            touched={formik.touched.section_id}
            onChange={(value) => formik.setFieldValue('section_id', value)}
            onBlur={formik.handleBlur}
          />
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col xs={24} sm={12} md={8} lg={6}>
          <FormSelect
            label="Seccional del solicitante"
            name="branch_id"
            placeholder="Seleccione"
            value={formik.values.branch_id}
            options={sedes}
            error={formik.errors.branch_id}
            touched={formik.touched.branch_id}
            onChange={(value) => formik.setFieldValue('branch_id', value)}
            onBlur={formik.handleBlur}
          />
        </Col>
        <Col xs={24} sm={12} md={8} lg={6}>
          <FormSelect
            label="Tipo de bien"
            name="item_type"
            placeholder="Seleccione"
            value={formik.values.item_type}
            options={itemTypes}
            error={formik.errors.item_type}
            touched={formik.touched.item_type}
            onChange={(value) => {
              if (value == '1') formik.setFieldValue('deliveries', 1);

              formik.setFieldValue('item_type', value);
            }}
            onBlur={formik.handleBlur}
          />
        </Col>
        <Col xs={24} sm={12} md={8} lg={6}>
          <FormSelect
            label="Dependencia"
            name="dependency_id"
            placeholder="Seleccione"
            value={formik.values.dependency_id}
            options={dependencies}
            error={formik.errors.dependency_id}
            touched={formik.touched.dependency_id}
            onChange={(value) => formik.setFieldValue('dependency_id', value)}
            onBlur={formik.handleBlur}
          />
        </Col>

        <Col xs={24} sm={12} md={8} lg={6}>
          <FormSelect
            label="Entregas en otras seccionales"
            name="other_deliver"
            placeholder="Seleccione"
            value={formik.values.other_deliver}
            options={entryDevelivery}
            error={formik.errors.other_deliver}
            touched={formik.touched.other_deliver}
            onChange={(value) => formik.setFieldValue('other_deliver', value)}
            onBlur={formik.handleBlur}
          />
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col xs={24} sm={12} md={8} lg={6}>
          {
            formik.values.entry_type === ENTRY_TYPE.CONTRATO ? (
              <>
                <FormDatePicker
                  label="Fecha de solicitud"
                  name="initial_date"
                  value={formik.values.initial_date || moment(new Date())}
                  error={formik.errors.initial_date}
                  touched={formik.touched.initial_date}
                  onChange={(value) => formik.setFieldValue('initial_date', value)}
                  onBlur={formik.handleBlur}
                  placeholder={formik.values.initial_date || ''}
                />
              </>
            ) : (
              <FormInput
                label="Fecha de solicitud"
                name="initial_dateDisable"
                placeholder="Solicitante"
                value={formik.values.initial_dateDisable || DateTime.now().toFormat('yyyy-MM-dd')}
                disabled
              />
            )
          }


        </Col>
        <Col xs={24} sm={12} md={8} lg={6}>
          <FormInput
            label="Número de factura"
            name="invoice_number"
            placeholder="Inserte"
            value={formik.values.invoice_number}
            error={formik.errors.invoice_number}
            touched={formik.touched.invoice_number}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Col>
        <Col xs={24} sm={12} md={8} lg={6}>
          <FormDatePicker
            label="Fecha Factura"
            name="invoice_date"
            value={formik.values.invoice_date}
            error={formik.errors.invoice_date}
            touched={formik.touched.invoice_date}
            onChange={(value) => formik.setFieldValue('invoice_date', value)}
            onBlur={formik.handleBlur}
          />
        </Col>
        <Col xs={24} sm={12} md={8} lg={6}>
          <FormInput
            label="Cantidad de Facturas"
            name="numberInvoices"
            placeholder="Seleccione"
            type="number"
            disabled={formik.values.item_type == 1}
            value={formik.values.numberInvoices}
            error={formik.errors.numberInvoices}
            touched={formik.touched.numberInvoices}
            onChange={(e) => {
              const value = e.target.value === '' ? 0 : Number(e.target.value);
              formik.setFieldValue('numberInvoices', value);
            }}
            onBlur={formik.handleBlur}
          />
        </Col>
      </Row>
      {formik.values.entry_type === '' && (
        <Row gutter={[16, 16]} style={{ marginBottom: '20px' }}>
          <Col xs={24} sm={12} md={8} lg={6}>
            <FormSelect
              label="Supervisor"
              name="supervisor"
              placeholder="Seleccione"
              value={formik.values.supervisor}
              options={supervisors}
              error={formik.errors.supervisor}
              touched={formik.touched.supervisor}
              onChange={(value) => formik.setFieldValue('supervisor', value)}
              onBlur={formik.handleBlur}
            />
          </Col>
        </Row>
      )}

      {/* ORDEN DE COMPRA */}
      {formik.values.entry_type === ENTRY_TYPE.ORDEN_COMPRA && (
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12} md={8} lg={6}>
            <FormSelect
              label="Supervisor"
              name="supervisor"
              placeholder="Seleccione"
              value={formik.values.supervisor}
              options={supervisors}
              error={formik.errors.supervisor}
              touched={formik.touched.supervisor}
              onChange={(value) => formik.setFieldValue('supervisor', value)}
              onBlur={formik.handleBlur}
            />
          </Col>
          <Col xs={24} sm={12} md={8} lg={6}>
            <FormInput
              label="Valor de la factura/ adquisición"
              name="invoice_value"
              type="text"
              placeholder="Valor factura"
              value={
                formik.values.invoice_value
                  ? formatNumber(formik.values.invoice_value)
                  : ''
              }
              error={formik.errors.invoice_value}
              touched={formik.touched.invoice_value}
              onChange={(e) => handleInvoiceValueChange(e, formik)}
              onBlur={formik.handleBlur}
              prefix="$"
            />
          </Col>
          <Col xs={24} sm={12} md={8} lg={6}>
            <FormInput
              label="Número de orden de compra"
              name="purchase_order"
              placeholder="Inserte"
              value={formik.values.purchase_order}
              error={formik.errors.purchase_order}
              touched={formik.touched.purchase_order}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Col>
        </Row>
      )}
      {/* CONTRATO */}
      {formik.values.entry_type === ENTRY_TYPE.CONTRATO && (
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12} md={8} lg={6}>
            <FormSelect
              label="Supervisor"
              name="supervisor"
              placeholder="Seleccione"
              value={formik.values.supervisor}
              options={supervisors}
              error={formik.errors.supervisor}
              touched={formik.touched.supervisor}
              onChange={(value) => formik.setFieldValue('supervisor', value)}
              onBlur={formik.handleBlur}
            />
          </Col>
        </Row>
      )}
      {formik.values.entry_type === ENTRY_TYPE.CONTRATO && (
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12} md={8} lg={6}>
            <FormInput
              label="Valor de la factura/ adquisición"
              name="invoice_value"
              type="text"
              placeholder="Valor factura"
              value={
                formik.values.invoice_value
                  ? formatNumber(formik.values.invoice_value)
                  : ''
              }
              error={formik.errors.invoice_value}
              touched={formik.touched.invoice_value}
              onChange={(e) => handleInvoiceValueChange(e, formik)}
              onBlur={formik.handleBlur}
              prefix="$"
            />
          </Col>
          <Col xs={24} sm={12} md={8} lg={6}>
            <FormInput
              label="Número de contrato"
              name="contract_number"
              placeholder="Inserte"
              value={formik.values.contract_number}
              error={formik.errors.contract_number}
              touched={formik.touched.contract_number}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Col>
          <Col xs={24} sm={12} md={8} lg={6}>
            <FormDatePicker
              label="Fecha inicio contrato"
              name="contract_start_date"
              value={formik.values.contract_start_date}
              error={formik.errors.contract_start_date}
              touched={formik.touched.contract_start_date}
              onChange={(value) =>
                formik.setFieldValue('contract_start_date', value)
              }
              onBlur={formik.handleBlur}
            />
          </Col>
          <Col xs={24} sm={12} md={8} lg={6}>
            <FormDatePicker
              label="Fecha final contrato"
              name="contract_end_date"
              value={formik.values.contract_end_date}
              error={formik.errors.contract_end_date}
              touched={formik.touched.contract_end_date}
              onChange={(value) =>
                formik.setFieldValue('contract_end_date', value)
              }
              onBlur={formik.handleBlur}
            />
          </Col>
        </Row>
      )}

      {/* SENTENCIA JUDUCIALjudgment */}
      {formik.values.entry_type === ENTRY_TYPE.SENTENCIA_JUDICIAL && (
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12} md={8} lg={6}>
            <FormSelectSearch
              label="Número de Juzgado"
              name="judgment_number_sentencia"
              placeholder="Inserte"
              options={terceros}
              value={formik.values.judgment_number_sentencia}
              error={formik.errors.judgment_number_sentencia}
              touched={formik.touched.judgment_number_sentencia}
              onChange={(value) =>
                console.log("QWW", value)
              }
              onBlur={() =>
                formik.setFieldTouched('judgment_number_sentencia', true)
              }
            />
          </Col>
          <Col xs={24} sm={12} md={8} lg={6}>
            <FormInput
              label="Número de proceso de sentencia"
              name="judgment_process_number_sentencia"
              placeholder="Inserte"
              value={formik.values.judgment_process_number_sentencia}
              error={formik.errors.judgment_process_number_sentencia}
              touched={formik.touched.judgment_process_number_sentencia}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Col>
          <Col xs={24} sm={12} md={8} lg={6}>
            <FormInput
              label="Valor de la factura/ adquisición"
              name="invoice_value"
              type="text"
              placeholder="Valor factura"
              value={
                formik.values.invoice_value
                  ? formatNumber(formik.values.invoice_value)
                  : ''
              }
              error={formik.errors.invoice_value}
              touched={formik.touched.invoice_value}
              onChange={(e) => handleInvoiceValueChange(e, formik)}
              onBlur={formik.handleBlur}
              prefix="$"
            />
          </Col>
        </Row>
      )}

      {/* DONACION */}
      {formik.values.entry_type === ENTRY_TYPE.DONACION && (
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12} md={8} lg={6}>
            <FormInput
              label="Documento de donación"
              name="donation_document_donation"
              placeholder="Documento"
              value={formik.values.donation_document_donation}
              error={formik.errors.donation_document_donation}
              touched={formik.touched.donation_document_donation}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Col>
          <Col xs={24} sm={12} md={8} lg={6}>
            <FormInput
              label="Valor de la factura/ adquisición"
              name="invoice_value"
              type="text"
              placeholder="Valor factura"
              value={
                formik.values.invoice_value
                  ? formatNumber(formik.values.invoice_value)
                  : ''
              }
              error={formik.errors.invoice_value}
              touched={formik.touched.invoice_value}
              onChange={(e) => handleInvoiceValueChange(e, formik)}
              onBlur={formik.handleBlur}
              prefix="$"
            />
          </Col>
        </Row>
      )}



      {/* comodato */}
      {formik.values.entry_type === ENTRY_TYPE.COMODATO && (
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12} md={8} lg={6}>
            <FormInput
              label="Valor de la factura/ adquisición"
              name="invoice_value"
              type="text"
              placeholder="Valor factura"
              value={
                formik.values.invoice_value
                  ? formatNumber(formik.values.invoice_value)
                  : ''
              }
              error={formik.errors.invoice_value}
              touched={formik.touched.invoice_value}
              onChange={(e) => handleInvoiceValueChange(e, formik)}
              onBlur={formik.handleBlur}
              prefix="$"
            />
          </Col>
          <Col xs={24} sm={12} md={8} lg={6}>
            <FormInput
              label="Número de contrato comodato"
              name="number_comodato"
              placeholder="número contrato"
              type="text"
              value={formik.values.number_comodato}
              error={formik.errors.number_comodato}
              touched={formik.touched.number_comodato}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Col>
          <Col xs={24} sm={12} md={8} lg={6}>
            <FormDatePicker
              label="Fecha inicio de contrato comodato"
              name="start_date_comodato"
              value={formik.values.start_date_comodato}
              error={formik.errors.start_date_comodato}
              touched={formik.touched.start_date_comodato}
              onChange={(value) =>
                formik.setFieldValue('start_date_comodato', value)
              }
              onBlur={() => formik.setFieldTouched('start_date_comodato', true)}
            />
          </Col>
          <Col xs={24} sm={12} md={8} lg={6}>
            <FormDatePicker
              label="Fecha final de contrato comodato"
              name="end_date_comodato"
              value={formik.values.end_date_comodato}
              error={formik.errors.end_date_comodato}
              touched={formik.touched.end_date_comodato}
              onChange={(value) =>
                formik.setFieldValue('end_date_comodato', value)
              }
              onBlur={() => formik.setFieldTouched('end_date_comodato', true)}
            />
          </Col>

        </Row>
      )}
      {/* Acuerdo interadministrativo */}
      {formik.values.entry_type === ENTRY_TYPE.ACUERDO_INTERADMINISTRATIVO && (
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12} md={8} lg={6}>
            <FormInput
              label="Número de Acuerdo interadministrativo"
              name="number_acuerdoInteradministrativo"
              placeholder="número Acuerdo"
              type="text"
              value={formik.values.number_acuerdoInteradministrativo}
              error={formik.errors.number_acuerdoInteradministrativo}
              touched={formik.touched.number_acuerdoInteradministrativo}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Col>
          <Col xs={24} sm={12} md={8} lg={6}>
            <FormDatePicker
              label="Fecha inicial acuerdo interadministrativo"
              name="start_date_acuerdoInteradministrativo"
              value={formik.values.start_date_acuerdoInteradministrativo}
              error={formik.errors.start_date_acuerdoInteradministrativo}
              touched={formik.touched.start_date_acuerdoInteradministrativo}
              onChange={(value) =>
                formik.setFieldValue(
                  'start_date_acuerdoInteradministrativo',
                  value
                )
              }
              onBlur={() =>
                formik.setFieldTouched(
                  'start_date_acuerdoInteradministrativo',
                  true
                )
              }
            />
          </Col>
          <Col xs={24} sm={12} md={8} lg={6}>
            <FormDatePicker
              label="Fecha final acuerdo interadministrativo"
              name="end_date_acuerdoInteradministrativo"
              value={formik.values.end_date_acuerdoInteradministrativo}
              error={formik.errors.end_date_acuerdoInteradministrativo}
              touched={formik.touched.end_date_acuerdoInteradministrativo}
              onChange={(value) =>
                formik.setFieldValue(
                  'end_date_acuerdoInteradministrativo',
                  value
                )
              }
              onBlur={() =>
                formik.setFieldTouched(
                  'end_date_acuerdoInteradministrativo',
                  true
                )
              }
            />
          </Col>
          <Col xs={24} sm={12} md={8} lg={6}>
            <FormInput
              label="Valor de la factura/ adquisición"
              name="invoice_value"
              type="text"
              placeholder="Valor factura"
              value={
                formik.values.invoice_value
                  ? formatNumber(formik.values.invoice_value)
                  : ''
              }
              error={formik.errors.invoice_value}
              touched={formik.touched.invoice_value}
              onChange={(e) => handleInvoiceValueChange(e, formik)}
              onBlur={formik.handleBlur}
              prefix="$"
            />
          </Col>
        </Row>
      )}

      {/* Decomiso */}
      {formik.values.entry_type === ENTRY_TYPE.DECOMISO && (
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12} md={8} lg={6}>
            <FormInput
              label="Número Juzgado/Fiscalía"
              name="number_decomiso"
              placeholder="número Acuerdo"
              type="text"
              value={formik.values.number_decomiso}
              error={formik.errors.number_decomiso}
              touched={formik.touched.number_decomiso}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Col>
          <Col xs={24} sm={12} md={8} lg={6}>
            <FormInput
              label="Número Proceso Juzgado/Fiscalía"
              name="number_decomisoProceso"
              placeholder="número Acuerdo"
              type="text"
              value={formik.values.number_decomisoProceso}
              error={formik.errors.number_decomisoProceso}
              touched={formik.touched.number_decomisoProceso}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Col>
          <Col xs={24} sm={12} md={8} lg={6}>
            <FormInput
              label="Valor de la factura/ adquisición"
              name="invoice_value"
              type="text"
              placeholder="Valor factura"
              value={
                formik.values.invoice_value
                  ? formatNumber(formik.values.invoice_value)
                  : ''
              }
              error={formik.errors.invoice_value}
              touched={formik.touched.invoice_value}
              onChange={(e) => handleInvoiceValueChange(e, formik)}
              onBlur={formik.handleBlur}
              prefix="$"
            />
          </Col>
        </Row>
      )}

      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <CustomTextArea
            label="Observaciones"
            name="observations"
            placeholder="Observaciones"
            value={formik.values.observations}
            error={formik.errors.observations}
            touched={formik.touched.observations}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Col>
      </Row>
      {/* INFORMACION DE CONTACTO */}
      {formik.values.entry_type !== ENTRY_TYPE.ORDEN_COMPRA && formik.values.entry_type !== ENTRY_TYPE.CONTRATO && (
        <>
          <div className="ContactoTitleModalEntradas">
            <CustomText text='Información de contacto' />
            <Divider />
          </div>
          <Row gutter={[16, 16]}>


            <Col xs={24} sm={12} md={8} lg={6}>
              <FormInput
                label="NIT"
                name="contact_nit"
                placeholder="Nit"
                value={formik.values.contact_nit}
                error={formik.errors.contact_nit}
                touched={formik.touched.contact_nit}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <FormInput
                label="Nombre"
                name="contact_name"
                placeholder="Nombre"
                value={formik.values.contact_name}
                error={formik.errors.contact_name}
                touched={formik.touched.contact_name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <FormInput
                label="Persona de contacto"
                name="contact_person"
                placeholder="Persona de contacto"
                value={formik.values.contact_person}
                error={formik.errors.contact_person}
                touched={formik.touched.contact_person}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <FormInput
                label="Télefono de contacto"
                name="contact_phone"
                placeholder="Télefono de contacto"
                value={formik.values.contact_phone}
                error={formik.errors.contact_phone}
                touched={formik.touched.contact_phone}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Col>
          </Row>
        </>

      )}
      <Divider />
      <Row align="middle" justify="center" style={{ marginBottom: '20px' }}>
        <Col flex="auto" style={{ textAlign: 'center' }}>
          <Title level={4} style={{ margin: 0 }}>
            Documentos cargados
          </Title>
        </Col>
        <Col>
          <Button
            disabled={
              formik.values.deliveries > 1 && formik.values.other_deliver
                ? false
                : true
            }
            type="primary"
            onClick={() => setIsOpen?.(true)}
          >
            Entrega en otras seccionales
          </Button>
        </Col>
      </Row>

      <Row>
        <Col xs={24}>
          <UploadWithTable onFilesChange={onFiles} files={files} />
        </Col>
      </Row>

    </>
  );
};

export default EntryRequestCreateView;
