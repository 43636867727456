import React, { Fragment } from 'react';
import { ModalType, useFormikPetitionFilters } from '../../../../utils';
import { PetitionsFilter } from '../../types';
import { useEntryStates, useSupervisors } from '../../../../share';
import { usePetitions, usePetitionsById } from '../../../../hooks';
import PetitionsView from '../Views/PetitionsView';
import PetitionsDetailContainer from './PetitionsDetailContainer';

const PetitionsContainer: React.FC = () => {
  const [filter, setFilter] = React.useState<PetitionsFilter>();
  const { data: entryStates } = useEntryStates();
  const { data: supervisors } = useSupervisors();
  //manage state modal
  const [modalState, setModalState] = React.useState<{
    type: ModalType;
    id?: string;
  }>({ type: null, id: undefined });
  const {
    data: petitions,
    isPending: loadList,
    refetch,
  } = usePetitions(filter);

  const { data: petition, isPending: loadById } = usePetitionsById(
    modalState.id
  );
  const handleModalToggle = (type: ModalType, id?: string) => {
    setModalState((prev) => ({
      type: prev.type === type ? null : type,
      id: id,
    }));
  };

  const handlePagination = (page: number) => {
    setFilter({
      page: page.toString(),
    });
  };

  const handleFilter = async (values: Partial<PetitionsFilter>) => {
    setFilter({
      ...values,
      created_at: values.created_at
        ? new Date(values.created_at).toISOString().split('T')[0]
        : '',
    });
    refetch();
  };

  const formik = useFormikPetitionFilters({ onSubmit: handleFilter });

  return (
    <Fragment>
      <PetitionsDetailContainer
        isOpen={modalState.type === 'detail'}
        OnClose={() => handleModalToggle('detail')}
        data={petition}
        isLoading={loadById}
      />

      <PetitionsView
        isLoading={loadList}
        data={petitions}
        supervisors={supervisors}
        states={entryStates}
        formik={formik}
        onViewCreate={() => handleModalToggle('create')}
        onViewDetail={(id) => handleModalToggle('detail', id)}
        onPagination={handlePagination}
        onResetFilters={() => {
          handleFilter({});
          formik.resetForm();
        }}
      />
    </Fragment>
  );
};

export default PetitionsContainer;
