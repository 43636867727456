import { useFormik } from 'formik';
import type { EntrySeccionales } from '../../modules/EntryRequests/types';
import * as Yup from 'yup';

interface UseEntryRequestFormikProps {
  onSubmit: (values: EntrySeccionales) => void;
}

const entrySeccionalesValidationSchema = Yup.object().shape({
  section_id: Yup.string().required('Requerido'),
  invoice_number: Yup.string().required('Requerido'),
  section_label: Yup.string().required('Requerido'),
  deliveries: Yup.number().required('Requerido').min(1, 'Debe ser mínimo 1'),
});

export const useEntrySeccionales = ({
  onSubmit,
}: UseEntryRequestFormikProps) => {
  return useFormik<EntrySeccionales>({
    initialValues: {
      section_id: '',
      invoice_number: '',
      deliveries: 0,
      section_label: '',
    },
    validationSchema: entrySeccionalesValidationSchema,
    onSubmit,
  });
};
