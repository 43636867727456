export const handleInvoiceValueChange = (
  e: React.ChangeEvent<HTMLInputElement>,
  formik: any
) => {
  // Conservar sólo dígitos
  const digits = e.target.value.replace(/[^\d]/g, '');
  // Si no hay dígitos, establecer valor vacío
  if (!digits || digits === '0' || digits === '00') {
    formik.setFieldValue('invoice_value', '');
    return;
  }
  // Convertir a entero
  const intValue = parseInt(digits, 10) || 0;
  // Desplazar dos decimales
  const floatValue = intValue / 100;

  formik.setFieldValue('invoice_value', floatValue);
};
